import React, { createContext, useState, useContext, useEffect } from 'react';
import api from '../utils/api';
import { AuthContextProvider, useAuth } from './authContext';

const CompletionContext = createContext();

export const CompletionProvider = ({ children }) => {
  const [completedCourses, setCompletedCourses] = useState([]);
  const [seriesCompleted, setSeriesCompleted] = useState(false);
  const { userId } = useAuth(); // assuming useAuth provides userId

  useEffect(() => {
    // Fetch completed courses initially
    const fetchCompletionData = async () => {
      if (userId) {
        try {
          const response = await api.get(`/api/user/completions/${userId}`);
          const { completedCourses: fetchedCourses, isSeriesCompleted } = response.data;
          setCompletedCourses(fetchedCourses);
          setSeriesCompleted(isSeriesCompleted);
        } catch (error) {
          console.error("Error fetching course completions:", error);
        }
      }
    };

    fetchCompletionData();
  }, [userId]);

  const markCourseAsCompleted = async (courseId) => {
    try {
      await api.post('/api/user/completion', { userId, courseId });
      const response = await api.get(`/api/user/completions/${userId}`);
      const { completedCourses: updatedCourses, isSeriesCompleted} = response.data
      // Debugging
      // console.log('Updated completed courses and series status:', {
      //   updatedCourses,
      //   isSeriesCompleted,
      // });

      setCompletedCourses(updatedCourses);
      setSeriesCompleted(isSeriesCompleted);
    } catch (error) {
      console.error('Error marking course as completed:', error);
    }
  };

  return (
    <CompletionContext.Provider value={{ completedCourses, markCourseAsCompleted, seriesCompleted }}>
      {children}
    </CompletionContext.Provider>
  );
};

export const useCompletion = () => useContext(CompletionContext);
