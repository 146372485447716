import React, { useState } from 'react';

// Specific Popup when user enrolls in a project

const ProjectPopup = ({ message, isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="fixed inset-0 bg-black opacity-50"
        onClick={onClose}
      ></div>

      {/* Popup box */}
      <div className="relative bg-white w-11/12 max-w-md mx-auto p-8 text-center rounded-lg shadow-lg z-10">
        <button
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-800"
          onClick={onClose}
        >
          &times;
        </button>

        {/* Content */}
        <h2 className="text-2xl font-bold mt-4 mb-2">We are processing your enrollment</h2>
        <p className="text-md text-gray-700 mb-4">{message}</p>
        <button
          className="w-full bg-primary text-white py-2 rounded text-center block hover:bg-primary-darker"
          onClick={onClose}
        >
          OK
        </button>
      </div>
    </div>
  );
};

export default ProjectPopup;
