import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import api from '../utils/api';
import { useAuth } from '../context/authContext';
import ProjectPopup from './ProjectPopup';
import { IoChevronBackOutline } from "react-icons/io5";


const CourseDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [course, setCourse] = useState({});
  const [all_courses, setAllCourses] = useState([]);
  const [activeTab, setActiveTab] = useState('About');
  const { isLoggedIn, userId } = useAuth();
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const {
    course_name = "Loading course information . . .",
    course_description = "",
    knowledge_gained = "",
    rating = "N/A",
    level = "Beginner",
    course_goals = [],
    course_testimonials = [],
    slide_path = null,
    video_path = null,
    outcomes = [],
  } = course || {};


  // Fetch individual courses
  useEffect(() => {
    const fetchCourseData = async () => {
      try {
        const response = await api.get(`/api/courses/${id}`);
        setCourse(response.data.course);
      } catch (error) {
        console.error('Error fetching course data:', error);
      }
    };
    fetchCourseData();
  }, [id]);

   // Fetch all courses data
   useEffect(() => {
    const fetchAllCourses = async () => {
      try {
        const response = await api.get('/api/courses'); // Adjust API endpoint
        setAllCourses(response.data);
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };
    fetchAllCourses();
  }, []);

  // TODO: Create proper loading page
  if (!course) return <div>Loading...</div>;

  const handleEnrollClick = async () => {
    // 2 types of cards: course + project, enrolling for each has diff behavior
    if (!isLoggedIn) {
      navigate('/home');
    } 
    else if (course.type === 'course'){
      // If logged in, proceed to the course page
      navigate(`/course/${id}`, {
        state: {
          title: course_name,
          videoSrc: video_path,
          slideDeck: slide_path,
          courseId: id,
          userId: userId,
        },
      });
    }
    else if (course.type === 'project') {
      await api.post('/projects/signup', {
        userId,
        projectName: course_name,
      });
      setIsPopupOpen(true);
    }
    else {
      console.error('Unknown type for this course or project.');
    }
  };

  const handleBackClick = () => {
    navigate(`/`);
  };

  // TODO: Change so all content is at bottom, tabs direct to each section
  const renderTabContent = () => {
    switch (activeTab) {
      case 'About':
        return (
          <div className="mt-8">
            <h2 className="text-2xl font-bold mb-4">What you'll learn</h2>
            <div className="grid grid-cols-2 gap-4">
              {course_goals && course_goals.length > 0 ? (
                course_goals.map((goal, index) => (
                  <div key={index} className="flex items-start space-x-2">
                    <span className="text-primary">✔</span>
                    <p className="text-gray-700">{goal}</p>
                  </div>
                ))
              ) : (
                <p>No course goals available.</p>
              )}
            </div>
          </div>
        );
      case 'Outcomes':
        return (
          <div className="mt-8">
            <h2 className="text-2xl font-bold mb-4">Advance your subject-matter expertise</h2>
            {outcomes.length > 0 ? (
              outcomes.map((outcome, index) => (
                <li key={index} className="mb-2 text-gray-700">{outcome}</li>
              ))
            ) : (
              <p>No outcomes available for this course.</p>
            )}
          </div>
        );
      case 'Courses':
        return (
          <div className="mt-8">
            {/* Specialization Header */}
            <h2 className="text-2xl font-bold mb-4">Specialization - 3 course series</h2>
    
            {/* Courses List */}
            <div className="mt-4 bg-white shadow-md rounded-lg py-2 px-4">
              {all_courses
              .filter(course => [1, 2, 3].includes(course.course_id))
              .map((course, index) => (
                <div key={index} className=" last:border-none border-b border-gray-300 py-4">
                  <Link to={`/course/${course.course_id}`}
                    className="text-lg font-bold text-primary-600 hover:underline cursor-pointer"
                  >
                    {course.course_name}
                  </Link>
                  <p className="text-gray-500">
                    Course {index + 1} • {course.level || 'Duration not specified'}
                  </p>
                </div>
              ))}
            </div>
          </div>
        );
      case 'Testimonials':
        // TODO: Update with new testimonials
        return (
          <div className="mt-8">
          <h2 className="text-2xl font-bold mb-6">Why people choose this course</h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {course_testimonials && course_testimonials.length > 0 ? (
              course_testimonials.map((testimonial, index) => (
                <div key={index} className="bg-white border border-gray-300 p-6 rounded-lg shadow-lg text-center">
                  {/* Dummy User Avatar */}
                  <div className="mb-4">
                    <img 
                      src={"/testimonials/testimonial" + String(index) + ".jpg"} 
                      alt={`User ${index + 1}`} 
                      className="w-16 h-16 rounded-full mx-auto"
                    />
                  </div>
                  {/* Dummy User Info */}
                  <div className="mb-2">
                    {/* Using some static names and dummy years */}
                    <p className="font-bold">{['Syed F. H.', 'Haidar A. R.', 'Ting H.', 'Michael X.'][index % 4]}</p>
                    <p className="text-sm text-gray-600">{[
                      'Columbia University',
                      'UN Analytics Intern, Columbia University',
                      'Columbia University',
                      'Columbia University'
                    ][index]}</p>
                  </div>
                  {/* Testimonial Content */}
                  <p className="text-gray-700 text-sm italic">
                    "{testimonial}"
                  </p>
                </div>
              ))
            ) : (
              <p>No testimonials available.</p>
            )}
          </div>
        </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      {/* Back Button */}
      <div className="ml-8">
        <button onClick={handleBackClick} className="flex items-center px-2 py-2 text-gray-800 font-regular">
          <IoChevronBackOutline className="mr-2 text-lg" />
          Back Home
        </button>
      </div>
      {/* Course Header */}
      <div className="p-8 mb-6">
        <h1 className="text-5xl font-bold mb-6">{course_name}</h1>
        <p className="text-lg text-gray-600 mb-6">{course_description}</p>
        <button onClick={handleEnrollClick} className="bg-primary text-white py-2 px-4 rounded mb-6">Enroll Now</button>
      {/* Custom Popup */}
      <ProjectPopup
        message="Project details will be sent to your email."
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
      />
      {/* Course Details */}
      <div className="w-full flex justify-center p-4 items-center">
        <div className="w-full max-w-5xl flex justify-evenly justify-between items-center bg-white p-4 rounded-lg shadow-md">
          {/* Course Series */}
          <div className="text-center">
            <p className="text-xl font-bold">3 course series</p>
            <p className="text-sm text-gray-600">Recommended in-depth knowledge</p>
          </div>
          <div className="h-16 w-px bg-gray-300"></div>
          {/* Rating */}
          <div className="text-center">
            <div className="flex items-center justify-center space-x-2">
              <span className="text-yellow-500">★</span>
              <p className="text-2xl font-semibold">{rating || 'N/A'}</p>
            </div>
            <p className="text-sm text-gray-500">(5 reviews)</p>
          </div>
          <div className="h-16 w-px bg-gray-300"></div>
          {/* Level */}
          <div className="text-center">
            <p className="font-semibold">{level || 'Beginner'}</p>
            <p className="text-xs text-gray-500">Recommended experience</p>
          </div>
          <div className="h-16 w-px bg-gray-300"></div>
          {/* Schedule */}
          <div className="text-center">
            <p className="font-semibold">Flexible schedule</p>
            <p className="text-xs text-gray-500">Learn at your own pace</p>
          </div>
        </div>
      </div>

      </div>

      {/* Tabs Section */}
      <div className="border-b border-gray-200 mt-8">
        <nav className="flex space-x-4">
          <button
            className={`py-2 px-4 ${activeTab === 'About' ? 'text-primary border-b-2 border-primary' : 'text-gray-500'}`}
            onClick={() => setActiveTab('About')}
          >
            About
          </button>
          <button
            className={`py-2 px-4 ${activeTab === 'Outcomes' ? 'text-primary border-b-2 border-primary' : 'text-gray-500'}`}
            onClick={() => setActiveTab('Outcomes')}
          >
            Outcomes
          </button>
          <button
            className={`py-2 px-4 ${activeTab === 'Courses' ? 'text-primary border-b-2 border-primary' : 'text-gray-500'}`}
            onClick={() => setActiveTab('Courses')}
          >
            Courses
          </button>
          <button
            className={`py-2 px-4 ${activeTab === 'Testimonials' ? 'text-primary border-b-2 border-primary' : 'text-gray-500'}`}
            onClick={() => setActiveTab('Testimonials')}
          >
            Testimonials
          </button>
        </nav>
      </div>

      {/* Tab content rendering */}
      {renderTabContent()}

      {/* Skills you'll gain */}
      <div className="mt-8">
        <h2 className="text-xl font-bold mb-4">Skills you'll gain</h2>
        <div className="flex flex-wrap gap-2">
          {/* Older code, config string, should now be okay in database */}
          {knowledge_gained && knowledge_gained.split(',').map((goal, index) => {
            // Remove leading 'and' and capitalize each word in the skill
            let cleanedGoal = goal.trim();
            // Check if the string starts with "and" and remove it
            if (cleanedGoal.toLowerCase().startsWith("and ")) {
              cleanedGoal = cleanedGoal.substring(4).trim();  // Remove 'and' and trim
            }
            // Capitalize the first letter of each word in the string
            cleanedGoal = cleanedGoal.split(' ').map(word => 
              word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            ).join(' ');
            return (
              <span key={index} className="px-3 py-1 bg-gray-200 text-sm text-gray-700 rounded-lg">
                {cleanedGoal}
              </span>
            );
          })}
        </div>
      </div>

    </div>
  );
};

export default CourseDetails;
