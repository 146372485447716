import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { IoFilterCircleOutline } from "react-icons/io5";
import api from '../utils/api';


// Function to map course name or ID to an image path
const getImageForCourse = (courseName) => {
  const courseImageMap = {
    'Paris Agreement, Climate Change Risk, and Transition Planning, and Reporting': '/course_imgs/planning.jpg',
    'Climate Change Policy, Economy and Technology': '/course_imgs/econ.jpg',
    'Net Zero': '/course_imgs/netzero.jpg',
    'Sustainable Development Goals': '/course_imgs/sdgs.jpg',
    'Nike’s Path to Sustainability: Leveraging SDGs for Global Impact and Brand Leadership': '/course_imgs/path.jpg',
    'Climate Risk to Finance Risk and Transition Planning': '/course_imgs/roadmap.jpg',
    'Net Zero Project': '/course_imgs/zero.jpg',
    'Policy, Technology, and Reporting': '/course_imgs/tech.jpg'
    // Add more mappings here
  };
  return courseImageMap[courseName] || '/course_imgs/sdgs.jpg'; // Default image if not found
};

const getFree = (courseName) => {
  const courseImageMap = {
    'Climate Change Risk, Transition Planning, and Reporting': true,
    'Climate Change Policy, Economy and Technology': true,
    'Net Zero': true,
    'Sustainable Development Goals': true,
    'Nike’s Path to Sustainability: Leveraging SDGs for Global Impact and Brand Leadership': true,
    'Climate Risk to Finance Risk and Transition Planning': false,
    'Net Zero Project': false,
    'Policy, Technology, and Reporting': false
    // Add more mappings here
  };
  return courseImageMap[courseName] || false; // Default image if not found
};




const CardTable = () => {
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [activeTab, setActiveTab] = useState('GEMI.AI'); 


  // Manually input relevant keywords for filtering
  const keywordOptions = [
    'Sustainability', 
    'Climate Change', 
    'Risk Management', 
    'ESG Reporting',
    'Case Study',
    'Strategy',
    'Data',
    'Project'
  ];

  // Fetch courses data from the API
  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await api.get('/api/courses');
        setCourses(response.data);
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };
    fetchCourses();
  }, []);

  // Handle selecting or deselecting keywords
  const handleKeywordChange = (keyword) => {
    setSelectedKeywords((prevKeywords) =>
      prevKeywords.includes(keyword)
        ? prevKeywords.filter((k) => k !== keyword)
        : [...prevKeywords, keyword]
    );
  };

  // Update the selected languages filter
  const handleLanguageChange = (language) => {
    setSelectedLanguages((prevLanguages) =>
      prevLanguages.includes(language)
        ? prevLanguages.filter((l) => l !== language)
        : [...prevLanguages, language]
    );
  };

  // Sort courses so that courses appear before projects
  const sortedCourses = courses.sort((a, b) => {
    if (a.type === 'course' && b.type !== 'course') return -1;
    if (a.type !== 'course' && b.type === 'course') return 1;
    return 0; 
  });

  // Filter courses based on search term, selected keywords, and selected languages
  const filteredCourses = sortedCourses.filter((course) => {
    const matchesSearchTerm =
      course.course_name.toLowerCase().includes(searchTerm.toLowerCase());

    const matchesLanguage =
      selectedLanguages.length === 0 ||
      selectedLanguages.includes(course.language);

    const matchesKeywords = selectedKeywords.length === 0 || 
      selectedKeywords.some((keyword) => 
        course.course_description.toLowerCase().includes(keyword.toLowerCase()) ||
        course.knowledge_gained.toLowerCase().includes(keyword.toLowerCase())
      );

    const matchesTab = activeTab === 'GEMI.AI'
      ? course.category === 'GEMI.AI'
      : course.category === 'SDG Community';

    return matchesSearchTerm && matchesKeywords && matchesLanguage && matchesTab;
  });

  return (
    
    <div className="container mt-[15px] mx-auto px-4 py-6 max-w-7xl">

      {/* Toggle Button for Filters */}
      <div className={`flex justify-between items-center mb-6 transition-all duration-300 ${showFilters ? 'ml-64' : ''}`}>
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search for courses"
          className="border p-2 w-full rounded focus:outline-none focus:border-primary"
        />
        <button
          onClick={() => setShowFilters(!showFilters)}
          className={`ml-4 px-3 py-2 rounded text-md font-medium flex items-center transition-colors duration-300 ${
            showFilters ? 'bg-primary text-white' : 'bg-gray-400 text-white'
          }`}
        >
          <IoFilterCircleOutline className="mr-2" /> 
          {showFilters ? 'Filters' : 'Filters'}
        </button>
      </div>

      {/* Tab Navigation */}
      <div className={`flex space-x-8 mb-6 transform transition-transform duration-300 ease-in-out ${showFilters ? 'translate-x-64' : 'translate-x-0'}`}>
      <button
          className={`pb-2 ${activeTab === 'GEMI.AI' ? 'text-primary border-b-2 border-primary' : 'text-gray-700'}`}
          onClick={() => setActiveTab('GEMI.AI')}
        >
          GEMI.AI
        </button>
        <button
          className={`pb-2 ${activeTab === 'SDG Community' ? 'text-primary border-b-2 border-primary' : 'text-gray-700'}`}
          onClick={() => setActiveTab('SDG Community')}
        >
          SDG Community
        </button>
      </div>
        <div
          className={`fixed mt-[100px] top-0 left-0 h-[700px] w-[230px] bg-white p-6 shadow-lg rounded-lg overflow-hidden transform transition-transform duration-300 ease-in-out z-10 ${showFilters ? 'translate-x-8 w-[280px]' : '-translate-x-full'}`}
        >
        <h3 className="font-bold text-lg mb-4">Keyword Filters</h3>
        
        {/* Manual Keyword Filter */}
        <div className="mb-6">
          <h4 className="font-semibold mb-2">Filter by Keywords</h4>
          <div>
            {keywordOptions.map((keyword) => (
              <label key={keyword} className="flex items-center mb-2">
                <input
                  type="checkbox"
                  onChange={() => handleKeywordChange(keyword)}
                  checked={selectedKeywords.includes(keyword)}
                />
                <span className="ml-2">{keyword}</span>
              </label>
            ))}
          </div>
        </div>

        {/* Language Filter */}
        <div className="mb-6">
          <h4 className="font-semibold mb-2">Filter by Language</h4>
          <div>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                onChange={() => handleLanguageChange('English')}
              />
              <span className="ml-2">English</span>
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                onChange={() => handleLanguageChange('Spanish')}
              />
              <span className="ml-2">Spanish</span>
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                onChange={() => handleLanguageChange('Chinese')}
              />
              <span className="ml-2">Chinese</span>
            </label>
          </div>
        </div>
      </div>

      {/* Course Cards Section */}
      <div className={`transition-all duration-300 ${showFilters ? 'ml-64' : ''}`}>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {filteredCourses.map((course) => (
            <div key={course.course_id} className="relative bg-white shadow-md rounded-lg overflow-hidden flex flex-col justify-between h-full">
              
              {/* Free Tab on Right Corner*/}
              {getFree(course.course_name) && (<div className="absolute top-0 right-0 bg-primary text-gray-700 text-xs font-bold px-2 py-1">
                Free
              </div>)}

              {/* Image Section */}
              <div className="h-40 w-full overflow-hidden">
                <img
                  src={getImageForCourse(course.course_name)}
                  alt={course.course_name}
                  className="object-cover h-full w-full"
                />
              </div>

              {/* Text and Content Section */}
              <div className="p-4 flex flex-col flex-grow">
                <h2 className="text-lg font-bold mb-2">{course.course_name}</h2>
                <p className="text-sm text-gray-700 mb-4">
                  Skills you'll gain: {course.knowledge_gained || 'N/A'}
                </p>

                <div className="flex justify-between items-center text-gray-600 text-sm mb-2">
                  <div className="flex items-center">
                    <svg className="w-4 h-4 text-yellow-400" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M10 15l-5.878 3.09L5.293 11.1 0 6.9l6.055-.555L10 0l3.945 6.345L20 6.9l-5.293 4.2L15.878 18 10 15z" />
                    </svg>
                    <span className="ml-1">{course.rating || 'N/A'}</span>
                  </div>
                </div>

                <p className="text-sm text-gray-700 mb-4">{course.level || 'N/A'}</p>

                {/* Spacer */}
                <div className="flex-grow"></div>

                {/* View Details Button */}
                <Link
                  to={`/course_details/${course.course_id}`}
                  className="inline-block bg-primary text-gray-700 px-3 py-2 rounded text-sm font-medium hover:bg-primary transition w-auto self-start inlin-block mt-auto"
                >
                  View Details
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CardTable;
