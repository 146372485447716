import { createContext, useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import api from '../utils/api';


// Create the AuthContext
export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userName, setUserName] = useState('');
  const [userId, setUserId] = useState(null);

  // Define checkAuthStatus inside the component
  const checkAuthStatus = async () => {
    try {
      const response = await api.get('/auth/verify', { withCredentials: true });
      if (response.data.isLoggedIn) {
        setIsLoggedIn(true);
        setUserName(response.data.user.name);
        setUserId(response.data.user.id);
      } else {
        setIsLoggedIn(false);
        setUserName('');
        setUserId(null);
      }
    } catch (error) {
      console.error('Authentication check failed:', error);
      setIsLoggedIn(false);
      setUserName('');
      setUserId(null);
    }
  };

  // Check login status on component mount and when location changes
  useEffect(() => {
    checkAuthStatus();
  }, [isLoggedIn]);

  const logout = async () => {
    try {
      await api.post('/logout', {}, { withCredentials: true });
      await checkAuthStatus();
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, logout, userName, userId }}>
      {children}
    </AuthContext.Provider>
  );
};

// Hook to use AuthContext in other components
export const useAuth = () => useContext(AuthContext);