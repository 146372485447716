import React, { useState, useEffect } from 'react';
import { useCompletion } from '../context/completionContext';
import api from '../utils/api';



const Popup = ({ allCoursesCompleted, nextCourse, surveyLink, courseId, userId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [responses, setResponses] = useState({});
  const [isSurveyCompleted, setIsSurveyCompleted] = useState(false);
  const [hoveredRating, setHoveredRating] = useState(0);

  // Manual survey questions, matches ones in database
  const surveyQuestions = [
    { text: 'How would you rate your experience in this course?', id: 1 },
    { text: 'Course Applicability', id: 2 },
    { text: 'Course Length', id: 3 },
    { text: 'Your overall experience with GEMI.Learn', id: 4 },
    { text: 'Anything to improve?', id: 5 },
  ];

  useEffect(() => {
    // Show the popup only if 3 courses are completed
    console.log('Popup trigger conditions met?:', { allCoursesCompleted, nextCourse });
    if (allCoursesCompleted || nextCourse) {
      setIsOpen(true);
    }
  }, [allCoursesCompleted, nextCourse]);

  // Star rating for survey, each response should be {}
  const handleStarClick = (rating) => {
    const updatedResponses = {
      ...responses,
      [surveyQuestions[currentQuestionIndex].text]: {
        questionText: surveyQuestions[currentQuestionIndex].text,
        questionId: surveyQuestions[currentQuestionIndex].id,
        rating,
      },
    };

    setResponses(updatedResponses);

    if (currentQuestionIndex < surveyQuestions.length - 1) {
      setCurrentQuestionIndex((prev) => prev + 1);
    } else {
      setIsSurveyCompleted(true);
      saveSurveyResponses(updatedResponses);
    }
  };

  const saveSurveyResponses = async (responses) => {
    try {
      // Set the survey responses to be sent to database
      const responsePayload = Object.values(responses).map(({ questionText, questionId, rating }) => ({
        questionText,
        questionId,
        rating,
      }));

      // Debugging
      // console.log('Survey responses being sent:', {
      //   userId,
      //   courseId,
      //   responses: responsePayload,
      // });
  
      await api.post('/survey/responses', {
        userId,
        courseId,
        responses: responsePayload,
      });
    } catch (error) {
      console.error('Error saving survey responses:', error);
    }
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          {/* Dark overlay */}
          <div
            className="fixed inset-0 bg-black opacity-50"
            onClick={closePopup}
          ></div>

          {/* Popup box */}
          <div className="relative bg-white w-11/12 max-w-md mx-auto p-8 text-center rounded-lg shadow-lg z-10">
            <button className="absolute top-4 right-4 text-gray-500 hover:text-gray-800" onClick={closePopup}>
              &times;
            </button>

            {/* The survey comes first in popup */}
            {/* Depending if series completed or next course popup window is diff */}
            {isSurveyCompleted ? (
              allCoursesCompleted ? (
                <>
                  <h2 className="text-2xl font-bold mb-4">Congratulations!</h2>
                  <p className="text-md text-gray-700 mb-4">
                    You have completed all courses in the series. Please fill out this survey to complete the program and your certificate will be emailed to you!
                  </p>
                  <img
                    src="/course_imgs/certificate.jpg"
                    alt="Certificate"
                    className="w-1/2 h-1/2 mb-4 rounded mx-auto"
                  />
                  <a
                    href={surveyLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="w-full bg-primary text-white py-2 rounded mb-2 text-center block hover:bg-primary-darker"
                    onClick={closePopup}
                  >
                    Complete Survey
                  </a>
                  <p className="text-center text-sm text-gray-500 cursor-pointer" onClick={closePopup}>
                    No, I don’t want to complete the survey
                  </p>
                </>
              ) : nextCourse ? (
                <>
                  <h2 className="text-2xl font-bold mb-4">Next Course Available!</h2>
                  <p className="text-lg font-bold text-gray-700 mb-2">{nextCourse.course_name}</p>
                  <p className="text-md text-gray-700 mb-4">{nextCourse.level}</p>
                  <button
                    className="w-full bg-primary text-white py-2 rounded mb-2 text-center block hover:bg-primary-darker"
                    onClick={() => {
                      window.location.href = `/course_details/${nextCourse.course_id}`;
                    }}
                  >
                    Start Next Course
                  </button>
                </>
              ) : null
            ) : (
              <>
                {/* Star Rating for Survey */}
                <h2 className="text-2xl font-bold mb-4">{surveyQuestions[currentQuestionIndex]?.text}</h2>
                <div className="flex justify-center space-x-2 mb-4">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <button
                      key={star}
                      className={`text-4xl ${
                        star <= (hoveredRating || responses[surveyQuestions[currentQuestionIndex]?.text]?.rating || 0)
                          ? 'text-yellow-400'
                          : 'text-gray-300'
                      }`}
                      onClick={() => handleStarClick(star)}
                      onMouseEnter={() => setHoveredRating(star)}
                      onMouseLeave={() => setHoveredRating(0)}
                    >
                      ★
                    </button>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Popup;
