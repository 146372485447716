import axios from 'axios';

// api used in components to refer to correct backend URL
// Vercel already has this env in member vars
// For local hosting must set REACT_APP_API_URL in env

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'https://apilearn.gemi-ai.tech',  // Backend API URL
  withCredentials: true,  // Ensure cookies (JWT) are sent with each request
});
export default api;
